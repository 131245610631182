// Page level element choices
.ccm-page,
.ccm-container {
  > div.container {
    // The goal of this – any page level objects like layouts or blocks wrapped in a container that are NOT Concrete
    // containers should have automatic padding. Without this you have to add tons of custom design CSS within the block
    // design tool
    @include rfs($container-margin-y, 'margin-top');
    @include rfs($container-margin-y, 'margin-bottom');
  }

  // Let's add the same spacing even in edit mode.
  div[data-container=block] {
    > div.container {
      margin-top: $container-margin-y;
      margin-bottom: $container-margin-y;
    }
  }
}

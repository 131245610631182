// Bos Sod colour definitions

$primary: #3d5c49;
$secondary: #00a03c;
$tertiary: #748994;
$success: $secondary;
$danger: #600;
$info: #36b;
$warning: #996;
$light-grey: #f7f6f3;
$black: #000;
$grey: #e4e4e4;
$dark-grey: #202020;
$white: #ffffff;
$green: #51873e;
$dark-green: #03391f;
$light-green: #e9f3e6;
$light-grey-green: #ebeeec;

$headings-font-family: 'open sans', sans-serif;
$font-family-base: 'open sans', sans-serif;
$link-color: $secondary;

$border-wrapper-max-widths: (
        "container-wide": 1600px,
        "container": 1320px
) !default;

// 3rem gutter * 16px base font size
$border-wrapper-gutter-compensation: 3*16;

// set up the variables for colour options
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "tertiary" : $tertiary,
        "success": $success,
        "danger": $danger,
        "info": $info,
        "warning": $warning,
        "light-grey": $light-grey,
        "dark-grey": $dark-grey,
        "grey": $grey,
        "white" : $white,
        "dark-green" : $primary,
        "green": $green,
        "light-green" : $light-green,
        "light-grey-green" : $light-grey-green,
);

$custom-colors: (
);

$container-max-widths: (
        sm:1320px,
        md:1320px,
        lg:1320px,
        xl: 1320px,
        xxl: 1320px
);

$grid-breakpoints: (
        xxs: 0,        
        xs: 375px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl:1600px,
        xxxxl:1920px,
        xxxxxl:2500px
);

$spacer: 1rem;
$grid-gutter-width: 3rem;
$gutters: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 9
);

$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 9
);

$accordion-icon-color: $primary;
$accordion-button-active-color: $primary;

$btn-hover-bg-shade-amount: 75%;
$btn-font-size:1.1rem;

$nav-tabs-link-active-bg: $light-grey;

$progress-bg: #fff;

$box-shadow-sm: 0 .1rem .45rem rgba($black, .095);

// offcanvas (currently used for mobile nav slide-in) variables
$offcanvas-bg-color: $dark-green;
$offcanvas-color: $light-grey;

$theme-colors: map-merge($theme-colors, $custom-colors);
